@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}
/*footer css */
/* Styling for the footer container */
.footer_container {
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  /* Styling for each section (location and contact) */
  .footer_location,
  .footer_contact {
    flex: 1 1 300px; /* Adjust width as needed */
    margin-bottom: 20px;
  }
  
  /* Styling for headings */
  .footer_location h4,
  .footer_contact h4 {
    font-size: 18px;
    color:#000000;
    margin-bottom: 10px;
  }
  
  /* Styling for paragraph text */
  .footer_location p,
  .footer_contact p {
    font-size: 14px;
    color: #000000;
    line-height: 1.6;
  }
  
  /* Styling for contact items */
  .topbar_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .topbar_item svg {
    width: 24px;
    height: 24px;
    fill: #000000;
    margin-right: 10px;
  }
  
  .topbar_item p {
    font-size: 14px;
    color:#000000
  }
  
  .topbar_item span {
    color:#000000
  }
  .article_text {
    font-size: 1rem !important; /* Adjust the size for better readability */
    line-height: 1.5; /* Increase line height for better spacing between lines */
    color:#000000; /* Dark gray color for less strain on the eyes */
    margin: 1em 0; /* Add some margin to separate paragraphs */
    text-align: justify; /* Justify text for a cleaner look */
  }
 
  
  .miniature {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .miniature:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .miniature_image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .minia_desc {
    padding: 10px 15px;
    font-size: 16px;
    color: #333;
    
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
  }
  
  @media (max-width: 750px) {
    .article_text {
      font-size: 1rem !important;
      text-align: center; 
      line-height: 1.4;
     color: #000000;
       /* Adjust font size for smaller screens */
    }
  }
  
  

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer_location,
    .footer_contact {
      flex: 1 1 100%;
    }
  }
/* end footer css */ 


/* Add this to your CSS file */
.text2-container {
    display: flex;
    align-items: center;
}
.article_item2 {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .article_item2:hover {
    transform: scale(1.08); /* Increase size on hover */
  }
  
.show_more_button {
    display: block;
    margin: 20px auto;
    padding: 20px 30px;
    background-color: #8b1080;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-weight: bold;
  }


  .show_more_button:hover {
    background-color: rgb(251, 201, 27);
  }
  
.container-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .container-wrapper2 {
    display: flex;
    justify-content: center; /* Center horizontally */
    gap: 50px;
    margin-top: 70px; /* Adjust margin as needed */
  }
  
  .person {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
  }
  .person2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
  }
  
  .container {
    height: 312px;
    width: 400px;
    cursor: pointer;
    transform: scale(0.48);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .container2 {
    height: 380px; /* Increased height */
    width: 380px; /* Increased width */
    cursor: pointer;
    transform: scale(0.7); /* Adjust scale as needed */
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .circle {
    position: absolute;
    background: var(--color);
    height: 380px;
    width: 380px;
    top: 210px;
    left: 10px;
    border-radius: 50%;
  }
  .circle2 {
    position: absolute;
    background: var(--color);
    height: 440px; /* Increased height */
    width: 440px; /* Increased width */
    top: 180px; /* Adjust positioning */
    left: 0;
    right: 0;
    margin: auto; /* Center horizontally */
    border-radius: 90%; /* Circular shape */
  }
  
  .person img {
    position: relative;
    width: 340px;
    top: 164px;
    left: 22px;
    transform: translateY(20px) scale(1.15);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .person2 img {
    position: relative;
    width: 340px; /* Adjust width */
    top: 150px; /* Adjust positioning */
    left: 0;
    right: 0;
    margin: auto; /* Center horizontally */
    transform: translateY(10px) scale(1.2); /* Adjust scaling */
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .container-inner {
    position: relative;
    clip-path: path("M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z");
    top: -200px;
  }
  .container-inner2 {
    position: relative;
    clip-path: path("M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z");
    top: -200px;
  }
  
  .divider {
    height: 3px;
    width: 130px;
    border-radius: 5px;
    background: var(--color);
  }
  .divider2 {
    height: 3px;
    width: 130px;
    border-radius: 5px;
    background: var(--color);
  }

  
  .person h1 {
    color: #464646;
    margin: 16px 0 0 0;
  }
  .person2 h1 {
    color: #464646;
    margin: 16px 0 0 0;
  }
  
  .person p {
    font-family: Arial;
    color: #6e6e6e;
    font-size: 14px;
    margin-top: 5px;
  }
  .person2 p {
    font-family: Arial;
    color: #6e6e6e;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .container:hover {
    transform: scale(0.54);
  }
  .container2:hover {
    transform: scale(0.54);
  }
  
  .container:hover img {
    transform: translateY(0) scale(1.3);
  }
  .container2:hover img {
    transform: translateY(0) scale(1.3);
  }
  
  .team_title {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #8b1080;
    line-height: 1.2;
    margin-bottom: 30px;
    text-align: center;
}

.inline-container {
    display:flex;
    align-items: center;
    
    
}

.text2 {
    margin-right: 40px;
    margin-left: 60px;
    margin-bottom: -7%;
    font-weight: bold;
    /* Ajustez cet espace selon vos besoins */
}

.image-container .small-image {
    display:block;
    width: 70px; /* Adjust the width as needed */
    height: auto;
    border-radius: 50px;
    margin-right: 50px;
    /* Conserve les proportions de l'image */
}


html, body, #root {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

.header {
    display: flex;
    width: 93%;
    margin: 0 0 0 7%;
    height: calc(100% - 104px);
    align-items: center;
    padding: 30px 0 50px 0;
}

.header_content {
    display: flex;
    flex-direction: column;
    padding: 0 80px 0 20px;
}

span.header_t_bold {
    font-size: 56px;
    font-weight: 900;
    letter-spacing: 3px;
    color: rgb(25, 28, 30);
    line-height: 1.2;
}

span.header_t_bold span {
    color: #8b1080;
}

.header_t_regular, .header_t_small {
    color: #75777a;
    font-size: 21px;
    font-weight: 500;
}

.header_flex {
    display: flex;
    margin-bottom: 24px;
    margin-top: 10px;
}
.handwriting {
    font-family: 'Pacifico', cursive;
  }
.header_flex span {
    color: rgb(27, 27, 24);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 9px;
    border: 1px solid rgb(226, 226, 223);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 120px;
    text-align: center;
    margin-right: 10px;
}

.header_btn {
    padding: 0 32px;
    background: rgb(251, 201, 27);
    border-radius: 66px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    cursor: pointer;
}

.header_btn:hover {
    background: #8b1080;
    color: white;
}




.header_btn span {
    color: rgb(25, 28, 30);
    font-size: 16px;
    font-weight: 600;
}

.header_t_small {
    margin-bottom: 8px;
}

span.header_t_regular {
    margin-top: 14px;
}

.header_media {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.header_img {
    height: 450px;
    margin-right: 4%;
    flex: 0 0 calc(((100% - 25%) / 5) + 3%);
    margin-top: -50px;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
}

.header_img:last-child {
    margin-right: 0;
}

.header_flex span:last-child {
    margin-right: 0;
}

.header_img:nth-child(2n) {
    margin-top: 50px;
}

.about {
    display: flex;
    width: 86%;
    margin: 0 7%;
    align-items: center;
    padding: 30px 20px 50px 20px;
    align-items: flex-start;
    /* height: 100%; */
    align-items: center;
}
.about_list_item2 {
    color: #000000;
    
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 15px !important; /* Increased from 30px to 40px */
    font-style: italic;
}




.about_video_container {

 
  height: 10%;
  padding-right: 50px;
  border-radius: 50%;
}

.video_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ajustez la hauteur du conteneur selon vos besoins */
}

.video_iframe {
    border-radius: 420px; /* Ajustez la valeur selon vos besoins */
    overflow: hidden;
    height: 700px; /* Assure que le contenu de la vidéo reste dans la bordure arrondie */
    width: 80%; /* Ajustez la largeur selon vos besoins */
    max-width: 100%;
}

.about_info {
    flex: 0 0 50%;
    margin-bottom: auto;
}

.about_title {
    font-size:53px;
    font-weight: 1000;
    letter-spacing: px;
    color: rgb(25, 28, 30);
    line-height: 1;
    margin-bottom: 180px;
    position:relative;
    
    transform: translateX(20%);
    

}

.about_title span:nth-child(1) {
    color: #8b1080;
    margin-right: 10px;
    line-height: 46px;
}

.about_title span:nth-child(3) {
    color: rgb(251, 201, 27);
    margin-left: 10px;
    margin-right: 10px;
}



.about_item {
    background: #f8f4f8;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 40px 40px 30px 50px; /* Increased right padding */
    border-radius: 40px;
    position: relative;
    margin-right: -300px;
     /* Increased border size on the right */
}

.about_info_wrapper {
    position: relative;
    width: 200%;
    flex-direction: column;
    padding-right: -100px; /* Adjust based on the height of .about_item2 */
    padding-bottom: 320px;
  }
  .about {
    position: relative;
}

.about_background {
    height: 220px;
    background: linear-gradient(to left, #ac9eac, #ccc7cd);
    position:absolute;
    width: calc(100% - 80px);
    
    z-index: -2;
    left: 61.4%;
    transform: translateX(-50%);
    
}

  
 
  

.about_item:last-child {
    margin-bottom: 0;
}

.about_img {
  position: absolute;
  top: 10px;
  left: -20px;
  height: 64px;
  width: 64px;
  background: #e2e2df;
  border-radius: 64px;
  border: 2px solid rgb(251, 201, 27);
  box-shadow: 0 0 0 2px white, inset 0 0 0 2px white;
  background-size: cover;
  background-position: center;
}

span.about_name {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #8b1080;
    letter-spacing: 2px;
    line-height: 1;
    margin-top: 8px;
    display: block;
}

.about_list {
    display: flex;
    width: 50%;
    flex-direction: column;
    color: rgb(27, 27, 24);
    font-size: 15px;
    font-weight: 500;
    padding-left: 28px;
}

li.about_list_item span {
    color: #8b1080;
    font-weight: 600;
}

li.about_list_item {
    margin-bottom: 3px;
}

li.about_list_item:last-child {
    margin-bottom: 0;
}

.services {
    display: flex;
    width: 86%;
    margin: 0 7%;
    padding: 80px 20px 80px 20px;
    flex-direction: column;
    align-items: center;
}

.services_list {
  
    margin-bottom: 40px; /* Ajoute un espacement en bas de chaque élément pour éviter qu'ils ne soient collés */
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.services_title {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: rgb(25, 28, 30);
    line-height: 1.2;
    margin-bottom: 50px;
}

.services_title span:nth-child(2) {
    color: #8b1080;
    margin: 0 10px;
    line-height: 46px;
}

.services_title span:nth-child(4) {
    color: #ffd355;
    margin-left: 10px;
    margin-right: 10px;
}

.service_title {
    color: #8b1080;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 9px;
    border: 1px solid rgb(139 16 128 / 35%);
    padding: 16px 32px;
    border-radius: 55px;
    text-align: center;
    background: rgb(139 16 128 / 8%);
}

.services_item {
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;
}

ul.service_description li {
    color: #75777a;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 12px;
}

ul.service_description {
    padding-left: 50px;
    padding-right: 30px;
}

.action {
    background: #8b1080;
    padding: 80px 20px 50px 20px;
}
.action9 {
    background: #f6f1f6;
    padding: 80px 20px 50px 20px;
}
.action2 {
    background: linear-gradient(to top, rgb(251, 201, 27), white);
    padding: 80px 20px 50px 20px;
}.miniature_action {
    background: linear-gradient(to top, rgb(251, 201, 27), white);

    padding: 80px 20px 50px 20px;
  }
  
  .miniaturebox {
    padding: 32px 46px;
    width: 86%;
    margin: 0 auto;
    background: #fff;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto; /* Adjusted for dynamic content height */
  }
  
  .miniature_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .miniature_title {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #8b1080;
    line-height: 1.2;
    margin-bottom: 20px; /* Adjusted for better spacing */
    text-align: center;
  }
  
  .miniature_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px; /* Adjust the gap as needed */
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  }
  
  .miniature {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .miniature:hover {
    transform: scale(1.05);
  }
  
  .miniature_image {
    width: 100%;
    max-width: 300px; /* Adjust the size of the image */
    height: 200px; /* Set a fixed height */
    object-fit: cover;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  
  .miniature_description {
    font-size: 16px;
    text-align: center;
    color: #333;
  }
  .miniature_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .miniature_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
  }
  
  .miniature {
    flex: 1;
    margin: 0 10px;
  }
  
  .miniature img {
    width: 100%;
    height: auto;
  }
  
  .miniature .minia_desc {
    text-align: center;
    margin-top: 10px;
  }
  
  .form_section {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .form_container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .form_group {
    margin-bottom: 15px;
  }
  
  .form_group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form_group input[type="text"],
  .form_group input[type="email"],
  .form_group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button[type="submit"] {
    background-color: #8b1080;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #721066;
  }
  
.action3{
    background: linear-gradient(to top, rgb(251, 201, 27), white);
    
}

.btn_div{
    position: center;
}



.action_box {
    padding: 32px 46px;
    width: 86%;
    margin: 0 7%;
    background: #fff;
    border-radius: 26px;
    display: flex;
    height: 200px;
    align-items: center;
}

.action_box2 {
    padding: 32px 46px;
    width: 86%;
    margin: 0 7%;
    background: #fff;
    border-radius: 26px;
    display: flex;
    height: 200px;
    align-items: center;
}
.action_box3 {
    width: 100%;
    overflow: hidden;
    
}
.action_img {
    flex: 0 0 110px;
    height: 110px;
    background: #e2e2df;
    border-radius: 90px;
    margin-right: 32px;
    border: 3px solid rgb(251, 201, 27);
    box-shadow: inset 0 0 0 3px white;
    background-size: cover;
    background-position: center;
}


.action_name {
    font-size: 32px;
    font-weight: 700;
    color: rgb(25, 28, 30);
    line-height: 1;
    text-wrap: nowrap;
    margin-bottom: 10px;
}

.action_description {
    font-weight: 500;
    color: #75777a;
    line-height: 1;
}

.action_info {
    margin-right: 32px;
}
.action_box9 {
    padding: 50px 500px;
    width: 86%;
    margin: 0 7%;
    border-radius: 26px;
    display: flex;
    height: 200px;
    align-items: center;
  }
  
  .action_info9 {
    margin-right: 32px;
  }
  .action_img9 {
    flex: 0 0 110px;
    height: 110px;
    background: #e2e2df;
    border-radius: 90px;
    margin-right: 32px;
    border: 3px solid #8b1080;
    box-shadow: inset 0 0 0 3px white;
    background-size: cover;
    background-position: center;

}
.action_btn {
    width: 100%;
    padding: 0 32px;
    background: rgb(251, 201, 27);
    border-radius: 66px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 220px;
    cursor: pointer;
    white-space: nowrap;
    color: rgb(25, 28, 30);
    font-size: 14px;
    font-weight: 600;
    margin-left: 32px;
}

.action_btn:hover {
    background: #8b1080;
    color: white; 
    
    /* Change text color on hover */
}


.action_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    color: #75777a;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
}

.action_content > span:last-child {
    font-size: 21px;
    color: rgb(76 76 77);
}

.action_content > span:last-child span {
    text-transform: uppercase;
    font-weight: 600;
    color: #8b1080;
}

.steps {
    display: flex;
    width: 86%;
    margin: 0 7%;
    padding: 80px 20px 50px 20px;
    flex-direction: column;
    align-items: center;
}

.steps_title {
    position: relative;
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: rgb(25, 28, 30);
    line-height: 1.2;
    margin-bottom: 50px;
  }
  
  .cover_img {
    position: relative;
    width: 100%;
    height: 300px; 
    overflow: hidden;
  }
  
  .cover_img img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
    object-fit: cover;
  }
  
  
.steps_title span {
    margin-right: 10px;
}

.steps_title span:last-child {
    margin-right: 0;
}

.steps_title span:nth-child(1) {
    color: rgb(251, 201, 27);
}

.steps_title span:nth-child(4) {
    color: #8b1080;
}

.steps_container {
    display: flex;
    width: 100%;
    padding: 32px 46px;
    margin-bottom: 25px;
}

.step_item {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.step_number {
    padding-right: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.step_number span {
    height: 42px;
    display: flex;
    flex: 0 0 42px;
    border-radius: 42px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background: rgb(139 16 128 / 12%);
    color: #8b1080;
    border: 1px solid rgb(139 16 128 / 35%);
    margin-right: 10px;
}

.step_bar {
    width: 100%;
    height: 2px;
    background: rgb(139 16 128 / 12%);
    border-radius: 2px;
}

.step_title {
    font-size: 21px;
    font-weight: 600;
    color: #8b1080;
    line-height: 1;
    text-wrap: nowrap;
    margin-bottom: 16px;
}

.step_description {
    color: #75777a;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 12px;
    padding-right: 36px;
}

.step_btn {
    width: 100%;
    padding: 0 32px;
    background: rgb(251, 201, 27);
    border-radius: 66px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 260px;
    cursor: pointer;
    white-space: nowrap;
    color: rgb(25, 28, 30);
    font-size: 16px;
    font-weight: 600;
    margin-left: 32px;
}

.step_btn:hover {
    background-color: #8b1080;
    color: white; /* Change text color on hover */
}



.partners {
    display: flex;
    width: 86%;
    margin: 0 7%;
    padding: 50px 66px 50px 66px;
    flex-direction: column;
    align-items: center;
}

.partners_title {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: rgb(25, 28, 30);
    line-height: 1.2;
    margin-bottom: 60px;
}

.partners_title span:first-child {
     margin-right: 10px;
    color: #8b1080;
}

.partners_container {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
   
    margin-bottom: 80px;

}

.partner {
    height: 80px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
}

.blog {
    display: flex;
    width: 86%;
    margin: 0 7%;
    padding: 50px 66px 70px 66px;
    flex-direction: column;
    align-items: center;
}

.blog_title {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1px;
    color: rgb(25, 28, 30);
    line-height: 1.2;
    margin: 0 auto 50px auto;
    text-align: center;
}


.blog_title span {
    margin-right: 10px;
}

.blog_title span:nth-child(3) {
    color: rgb(251, 201, 27);
}

.blog_title span:nth-child(4) {
    color: #8b1080;
}

.blog_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.blog_container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 20px auto;
}







.article_title {
    font-size: 15px;
    font-weight: 600;
    color: #8b1080;
    line-height: 1;
    margin-bottom: 4px;
    width: 100%;
    line-height: 1.6;
}
.article_title2 {
    font-size: 15px;
    font-weight: 600;
    color: #8b1080;
    line-height: 1;
    margin-bottom: 4px;
    width: 100%;
    line-height: 1.6;
    text-align: center;
    
    margin: 10px 0;
}

.article_date {
    color: #75777a;
    font-size: 15px;
    font-weight: 400;
    padding-right: 36px;
    margin-bottom: 4px;
}

.article_read {
    color: #75777a;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 12px;
}
.article_read2 {
    font-size: 14px;
    margin: 10px 0;
}
.blog_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* This will add space between the articles */
  }
  
  .article_item {
    display: flex;
    flex-direction: column;
    width: 350px;
    /* Set a fixed width for each article item */
    /* To remove underline from the links */
  }
  .article_item2 {
    width: 300px;
    margin: 10px;
    text-decoration: none;
    color: #333;
  }
  
  .article_img {
    width: 1200px;
    height: 600px;
    border-radius: 3%;
    background-size: cover;
    background-position: center;
    transform: scale(1); /* Adjust the scale value as needed */
  }
  .article_img1{
    width: 340px;
    height: 250px;
    border-radius: 4%;
    background-size: cover;
    background-position: center;
    transform: scale(1);

  }
  .article_img2{
    width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;

  }

  .article_title,
  .article_read,
  .article_action {
    margin-top: 15px; /* Adds space between the content inside the article */
  }
  .article_title2,
  .article_read2,
  .article_action2{
    margin-top: 15px; /* Adds space between the content inside the article */
  }
  
.article_action {
    color: rgb(209 165 9);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    font-size: 14px;
}
.article_action2 {
    color: rgb(209 165 9);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    font-size: 14px;
   
  align-items: center;
  justify-content: space-between;
}
.article_action2 span {
    font-size: 14px;
    color: rgb(209 165 9);
  }
  .article_action2 svg {
    fill: rgb(209 165 9);
  }

.article_action svg {
    fill: rgb(209 165 9);
}

.footer {
    background: rgb(251, 201, 27);
    padding: 10px 15px;
    box-shadow: inset 0 4px 10px #0000000a;
}

.topbar {
    height: 32px;
    background: #ffd355;
    position: relative;
    border-bottom: 1px solid rgb(209 165 9);
    padding: 0 20px;
}

.navbar {
    height: 72px;
    border-bottom: 1px solid #e2e2df;
    box-shadow: 0 0px 4px 0 #00000024;
    padding: 0 20px;
}

.navbar_wrapper {
    width: 86%;
    margin: 0 7%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.logo_container img {
    display: block;
    height: 100%;
}

.logo_container {
    height: 100%;
    position: absolute;
    left: 0;
}

.nav_btn {
    background: rgb(251, 201, 27);
    color: rgb(25, 28, 30);
    font-size: 14px;
    font-weight: 600;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    border-radius: 66px;
    align-items: center;
    height: 36px;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.nav_btn:hover {
    background: #8b1080;
    color: white; /* Change the text color on hover */
}


.miniature {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .miniature:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .miniature_image {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .miniature_description {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    color: #555;
  }

.nav_links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}

.nav_links a {
    text-decoration: none;
    font-size: 15px;
    color: #000000;
    font-weight: 500;
}
.topbar_wrapper {
    width: 86%;
    margin: 0 7%;
    display: flex;
    height: 100%;
    align-items: center; /* This already centers items vertically within the wrapper */
}

.right_topbar, .left_topbar {
    display: flex; /* Ensure the container uses flexbox */
    align-items: center; /* Center items vertically within the container */
}

.right_topbar {
    margin-left: auto;
}

.right_topbar .topbar_item, 
.left_topbar .topbar_item {
    font-size: 12px;
    margin-left: 32px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    display: flex;
    align-items: center; /* Center items vertically */
    text-decoration: none;
    height: 40px; /* Set a height for better vertical alignment */
    line-height: 40px; /* Ensure the text is centered within the item */
}

.topbar_item svg {
    height: 18px;
    fill: #070707;
    margin-right: 8px;
    width: 18px;
}

.downbar_item p {
    margin-right: 19px;
    fill: #030303;
    align-items: center;
}


.service_description {
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
    max-width: 270px;
    margin: 16px auto 0 auto;
    color: #000000;
    align-items: center !important;
}

.partners_container img {
    display: block;
    height: 70px;
}

.partners_container img:first-child {
    height: 110px;
}

.partners_container img:last-child {
    height: 120px;
}

.footer_wrapper {
    width: 86%;
    margin: 0 7%;
    padding: 0 66px;
    display: flex;
    height: 100%;
}

.footer_logo .logo_container {
    height: 71px;
    position: static;
}

.footer_wrapper > * {
    flex: 0 0 calc((100%) / 3);
}

.footer_location h4, .footer_contact h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    
}

.footer_location p {
    color: #000000;
    font-size: 14px;
    line-height: 1.6;
}

.footer_location {
    padding-top: 20px;
    width: 100%;
}

.footer_contact {
    padding-top: 20px;
    padding-left: 40px;
    max-width: 270px;
    margin-left: auto;
    
}
.footer_contact .downbar_item p{
    color: #000000;
    font-size: 14px;
    line-height: 1.6;
    padding-left: 40px;
    
}  
.footer_contact .downbar_item svg{
    margin-right: 19px;
    color: #000000;
    margin-top: px;
    
} 

.footer .topbar_item {
    display: flex;
    color: #000000;
    font-size: 14px;
    margin-top: 7px;
    text-decoration: none;
   
}

.footer_vert_info svg {
    margin-right: 19px;
    fill: #030303;
    
}

.footer_contact svg{
    margin-right: 20px;
    margin-top: 5px;
    fill: #000000;
    
}


.footer_logo {
    padding-right: 40px;
}

.left_topbar {
    margin-right: auto;
    display: flex;
}

.left_topbar .topbar_item:first-child {
    margin-left: 0;
}
.left_topbar .topbar_item {
    margin-left: 20px;
    margin-top: 10px;
}
.right_topbar .topbar_item {
    margin-left: 20px;
    margin-top: 10px;
}


span.arobas {
    position: relative;
    top: -1px;
}

.video_iframe {
    max-height: 100%;
    width: 450px;
    border-radius: 420px;
    
}


.article_container {}

.article {
    padding: 40px 20px 100px 20px;
}

.article_wrapper {
    width: 90%;
    margin: 0 7%;
}

.article_wrapper h1 {
    color: #8b1080;
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 25px 0;
}

.article_wrapper p {
    color: #010000;
    margin: 25px 0 0 0;
}

.article_wrapper h2 {
    margin: 0;
    text-align: center;
    margin-left: 10px;

}


.articles-title {
    text-align: justify;
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .article_num {
    width: 100%; /* Adjust the width to make the element smaller */
    text-align: justify; /* Justify text */
    padding: 10px 0; /* Adjust padding as needed, removes left padding */
    font-size: smaller;
    line-height: 1.2;
   
}

.article_num2  {

    
    width: 100%; /* Adjust the width to make the element smaller */
     /* Center text */
    padding: 10px 0; /* Adjust padding as needed, removes left padding */
    font-size:18px;
    color: #8b1080;
    font-weight: bolder;
    text-align: center;

}
.article_num1 {

    
    width: 100%; /* Adjust the width to make the element smaller */
    text-align: center; /* Center text */
    padding: 10px 0; /* Adjust padding as needed, removes left padding */
    font-size:large;
    

}
.conclusion{
    width: 100%; /* Adjust the width to make the element smaller */
    text-align: center; /* Center text */
    padding: 10px 0; /* Adjust padding as needed, removes left padding */
    font-size: smaller;
}




.loading_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fcfcfb;
    flex-direction: column;
}

.loading_container img {
    width: 260px;
    margin-bottom: -20px;
}

.loading_container svg {
    height: 70px;
    width: 70px;
    margin-left: 30px;
}

.postuler {
    display: flex;
    width: 86%;
    margin: 0 7%;
    padding: 70px 20px 70px 20px;
    align-items: center;
    justify-content: space-evenly;
}

.postuler_title {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: rgb(25, 28, 30);
    line-height: 1.2;
    margin-bottom: 30px;
}

.postuler_title span {
    color: #8b1080;
}

.postuler_img_container {width: 100%;max-width: 400px; border-radius: 10%;}

.postuler_text {
    font-size: 15px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace; /* a font family known for its regular weight */
    color: #000000; /* your preferred color */
    padding: 5px; /* adds some space around the text */
    border-radius: 5px; /* rounds the corners of the text area */
    font-weight: normal; /* ensures the text is not bold */
    font-style: italic; /* makes the text italic */
}



  

.postuler_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    max-width: 510px;
}

.postuler_img {
    background: #e2e2df;
    width: 100%;
    aspect-ratio: 1;
    max-width: 400px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    border-radius: 10%;
}

.postuler_input {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
}

.postuler_input label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 6px;
}

.postuler_input input, .postuler_input select {
    height: 36px;
    border-radius: 36px;
    background: #f2f2f2;
    border: 2px solid transparent;
    padding: 0 16px;
    outline: none;
    color: rgb(0, 0, 0);
    width: 100%;
}

.postuler_input input:focus {
    border-color: #8b1080;
}

.postuler_submit {
    background: #8b1080;
    color: rgb(255 255 255);
    font-size: 15px;
    font-weight: 600;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    border-radius: 66px;
    align-items: center;
    height: 40px;
    cursor: pointer;
    margin-top: 14px;
    padding-top: 2px;
}

.postuler_flex {
    display: flex;
    width: 100%;
    gap: 24px;
}

.about_item p {
    font-size: 12px;
    color: #000000;
}

.article_header {
    display: flex;
    flex-direction: column;
}

.article_img_container {
    position: relative;
}

.article_info {
    
   
    bottom: 0;
    left: 0;
    width: 100%;
    color: white; /* Text color */
    text-align: center; /* Center text */
    padding: 50px 50px; /* Adjust padding as needed, includes left padding */
    box-sizing: border-box;
    text-align:center
    
    
}

.article_info h1{
    margin-bottom: 0;
    font-size: 40px;

    
}

.article_info2 h2 {
    text-align: center;
    font-size: 35px;
    padding: 30px 40px;
}
.article_info2 p {
    text-align: center;
    font-size: 20px;
    padding: 20px 35px;
}

.article_info2 > *:not(:last-child) {
    margin-right: 15px; /* Ajustez la marge selon vos besoins */
}
/* Add this to your CSS file */

.article_info p, .article_content {
    text-align: justify !important; /* Justify text */
    margin: 20px !important;
    
    /* Remove default margin */
}
.article_content {
    margin-right: 40px !important; /* Increase the right margin */
}
.article_info h1 h1{
    text-align: justify !important; /* Justify text */
    margin: 20px !important;
}
.postuler_option {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.postuler_option svg {
    height: 20px;
}

.postuler_option span {
    color: rgb(0, 0, 0);
    font-size: 14px;
}

.postuler_options {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.postuler_option.active svg {
    fill: #8b1080;
}

.postuler_option.active span {
    color: #8b1080;
    font-weight: 500;
}
#sticky-action {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust as needed */
    transition: all 0.3s ease; /* Smooth transition for the changes */
}

#sticky-action.smaller {
    transform: scale(30px); /* Scale down the element */
    padding: 30px; /* Adjust the padding as needed */
    font-size: 300px; /* Adjust the font size as needed */
}


.postuler_title_mobile {
    display: none;
}
.topbar_item_lang span {
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .show_more_button {
        display: block;
        margin: 20px auto;
        padding: 20px 30px;
        background-color: #8b1080;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
      }
    .article_item {
        display: flex;
        flex-direction: column;
        width: 350px;
        /* Set a fixed width for each article item */
        /* To remove underline from the links */
      }
  }
  
/* MOBILE */

@media only screen and (max-width: 768px) {
    .miniaturebox {
        padding: 20px;
        width: 90%;
      }
    
      .miniature_title {
        font-size: 28px;
        margin-bottom: 15px;
      }
    
      .miniature_image {
        max-width: 100%;
      }
    
      .miniature_description {
        font-size: 14px;
      }
    
    
  
    .article_img {
        width: 390px;
        height: 310px;
        border-radius: 3%;
        background-size: cover;
        background-position: center;
        transform: scale(1); /* Adjust the scale value as needed */
      }
    .topbar {
        display: block;
        height: auto; /* Adjust height automatically */
        padding: -20px 40px; /* Increase padding for better touch response */
    }
    
    .topbar_wrapper {
        display: flex; /* Use flexbox */
        flex-wrap: nowrap; /* Prevent items from wrapping */
        width: 300px; /* Adjust width according to your requirement */
    }
    
    .topbar_wrapper .item {
        flex: 3; /* Equal width for all items */
        /* You can also specify specific widths for each item */
    }
    
    .topbar .topbar_item:not(.topbar_item_lang) {
        display: none;
    }
    .right_topbar > .topbar_item:not(:nth-last-child(-n+3)) {
        display: none;
    }
    
    .action_box9 {
        padding: 0px;
        width: 86%;
        margin: 0 5%;
        border-radius: 26px;
        display: flex;
        height: 160px;
        text-align: center;
      }
      
      .action_info9 {
        margin-right: 10px;
        
        margin-top: 10px;
        
      }

      .action_img9 {
        margin-right: 0px;
        width: 100px;
    }

    .action_name {
        font-size: 22px;
        text-wrap: wrap;
       
    }
    
      .action_info9 {
        margin-right: 10px;
        
        margin-top: 10px;
        
      }

      .action_img9 {
        margin-right: 0px;
        width: 100px;
    }

    .action_name {
        font-size: 22px;
        text-wrap: wrap;
       
    }
   

   
    .navbar .logo_container {
        position: static;
        margin: 0 auto;
        height: 52px;
    }

    .nav_links {
        display: none;
    }

    .nav_btn {
        display: none;
    }

    .header {
        width: 100%;
        margin: 0;
        padding: 40px 20px;
        flex-direction: column;
        height: initial;
    }

    .header_content {
        padding: 0;
        align-items: center;
        width: 100%;
    }

    .header_t_bold {
        font-size: 20px !important;
        line-height: 1.2;
    }
    

    .header_t_regular, .header_t_small {
        font-size: 10px;
    }


    span.header_t_regular {
        margin-top: 8px;
    }

    .header_flex {
        flex-direction: column;
        width: 100%;
    }

    .header_flex span {margin: 0 auto 10px auto;width: 100%;max-width: 200px;border-radius: 10px;}

    .header_flex span:last-child {
        margin: 0 auto;
    }

    .header_btn {
        margin-bottom: 30px;
    }

    .header_media {
        width: calc(100% + 40px);
        padding: 20px 0;
        justify-content: center;
        height: initial;
        align-items: flex-start;
    }

    .header_img {
        height: 210px;
        flex: 0 0 calc(((100% - 25%) / 5) + 4%);
        margin-top: 0;
    }

    .about {
        flex-direction: column-reverse;
        height: initial;
        width: 100%;
        margin: 0;
        padding: 40px 35px;
    }

    .about_title {
        font-size: 35px;
        display: flex;
        align-items:center;
        justify-content: center;
        
       
    }

    .about_title span:nth-child(1) {
        margin-right: 7px;
        line-height: 25.2px;
    }

    .about_title span:nth-child(3) {
        margin-left: 6px;
        margin-right: 6px;
       
    }

    .about_video_container {
        flex: initial;
        padding-right: 0;
        height: 281px;
        width: 100%;
    }

    .about_info {
        margin-bottom: auto;
    }

    

    

    .about_item {
        margin-left: 30px;
    }

    .services_title {
        font-size: 21px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin-bottom: 35px;
        font-weight: 700;
    }

    .services_title span:nth-child(2) {
        margin: 0 5px;
        line-height: 25.2px;
    }

    .services_title span:nth-child(4) {
        margin-left: 5px;
        margin-right: 5px;
    }

    .service_title {
        padding: 8px 16px;
        max-width: 300px;
        margin: 0 auto;
        width: 100%;
        font-size: 15px;
    }

    .services_list {
        flex-direction: column;
        justify-content: center;
    }

    .service_description {
        font-size: 13px;
    }

    .services_item {
        margin-bottom: 30px;
    }

    .action {
        width: 100%;
        margin: 0;
        padding: 40px 35px;
    }

    .services {
        width: 100%;
        margin: 0;
        padding: 40px 20px;
    }

    .action_box {
        margin: 0;
        width: 100%;
        border-radius: 16px;
        padding: 32px 25px;
        flex-direction: column;
        height: initial;
        align-items: center;
        text-align: center;
    }

    .action_info {
        margin-right: 0;
        margin-bottom: 25px;
    }

    .action_name {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 6px;
    }

    .action_description {
        font-size: 14px;
    }

    .action_img {
        height: 80px;
        width: 80px;
        flex: initial;
        margin-right: 0;
        margin-bottom: 14px;
    }

    .action_content {
        font-size: 17px;
        max-width: 260px;
        margin-bottom: 16px;
    }

    .action_content > span:last-child {
        font-size: 18px;
    }

    .action_btn {
        margin: 0;
    }

    .steps {
        width: 100%;
        margin: 0;
        padding: 40px 35px;
    }

    .steps_title {
        font-size: 21px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin-bottom: 35px;
        font-weight: 700;
    }

    .steps_title span {
        margin-right: 6px;
    }

    .steps_container {
        padding: 0;
        flex-direction: column;
    }

    .step_bar {
        display: none;
    }

    .step_number {
        margin-bottom: 12px;
    }

    .step_number span {
        height: 32px;
        flex: 0 0 32px;
        font-size: 13px;
        font-weight: 500;
        padding-top: 2px;
    }
.text2 {
    margin-right: 40px;
    margin-left: 60px;
    margin-bottom: -38%;
    font-weight: bold;
    /* Ajustez cet espace selon vos besoins */
}
    .step_title {
        font-size: 18px;
        padding-bottom: 8px;
        margin-bottom: 0;
        padding-left: 35px;
        margin-left: 15px;
        height: 32px;
        margin-top: -36px;
    }

    .step_description {
        font-size: 14px;
        padding-right: 0;
        padding-bottom: 18px;
        margin-bottom: 0;
        padding-left: 35px;
        margin-left: 15px;
        border-left: 2px solid rgb(139 16 128 / 12%);
        padding-top: 12px;
    }

    .step_item {
        margin-bottom: 12px;
    }

    .step_btn {
        margin: 0 auto;
        padding: 0 21px;
        height: 48px;
        font-size: 16px;
    }

    .partners {
        width: 100%;
        margin: 0;
        padding: 40px 35px;
    }

    .partners_title {
        font-size: 21px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin-bottom: 35px;
        font-weight: 700;
    }

    .partners_title span:first-child {
        margin-right: 5px;
    }

    .partners_container img {
        height: 60px;
    }

    .partners_container {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 25px;
    }

    .partners_container img:first-child {
        height: 90px;
    }
    
    .blog {
        width: 100%;
        margin: 0;
        padding: 40px 36px;
    }

    .blog_title {
        font-size: 21px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 35px;
        font-weight: 700;
        justify-content: center;
        row-gap: 10px;
    }

    .blog_title span {
        margin-right: 5px;
        line-height: 1;
    }

    

    .footer_wrapper {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .footer_logo {
        padding-right: 0;
        text-align: center;
        margin-bottom: 20px;
    }

    .footer .topbar_item {
        flex-direction: column;
        align-items: center; /* Changed to center */
        margin-top: 10px;
       
    }
    

    .footer_contact {
        padding-left: 0;
        max-width: inherit;
        width: 100%;
        margin: 0;
    }

    .footer {
        padding: 20px;
    }

    .article_wrapper {
        width: 100%;
        margin: 0;
        text-align: justify;
    }
    
    .article_num span {flex: 0 0 20px;}

    .postuler_img_container {
        margin-bottom: 25px;
    }
    
    .postuler_form {
        margin: 0;
    }
    
    .postuler {
        width: 100%;
        margin: 0;
        padding: 50px 30px;
        flex-direction: column;
    }
    
    .postuler_flex {
        flex-direction: column;
        gap: 0;
    }
    
    .postuler_title {
        text-align: center;
        font-size: 20px;
        margin-bottom: 42px;

    }
    .title{
        text-align: center;
        font-size: 20px;
        margin-bottom: 42px;
    }
    
    .postuler_input {
        margin-bottom: 20px;
    }
    
    .postuler_option {
        align-items: flex-start;
    }

    .postuler_title {
        display: none;
    }

    .postuler_title_mobile {
        display: block;
        margin-bottom: 25px;
    }
    .about_title {
        margin-bottom: 60px;
        transform: none;
    }
    
    .about_info_wrapper {
        width: 200%;
    }
    
    .about_item {
        width: 100%;
        margin-left: 0;
        padding: 50px 20px 20px 20px;
        margin-bottom: 80px;
    }
    
    ul.about_list {
        width: 50%;
    }
    
    .about_img {
        top: -20px;
        left: 30px;
    }
    
    .about_video_container {
        height: initial;
        margin-top: 40px;
    }
    
    .about_background {
        left: 0;
        width: 100%;
        transform: none;
        bottom: 220px;
    }
    
    .action_box2 {
        height: initial;
    }
    
    .partners_container {
        margin-bottom: 0;
    }
    
    a.article_item {
        max-width: inherit;
    }
    
    .header_btn span {
        margin-bottom: 0;
    }
    .article_info {

    
   
        bottom: 0;
        left: 0;
        width: 100%;
        color: white; /* Text color */
        text-align: center; /* Center text */
        padding: 50px 20px; /* Adjust padding as needed, includes left padding */
        box-sizing: border-box;
        text-align:center
        
        
    }
    .article_num {
       
        /* Adjust the font size for better readability on phones */
    }
    .article_num p {
        width: 100%; /* Make the element wider on smaller screens */
        padding: 1px ; /* Adjust padding for smaller screens */
        font-size: 19px;
        text-align: justify;
        line-height: 1.2;
        /* Adjust the font size for better readability on phones */
    }
    .conclusion p {
        width: 100%; /* Make the element wider on smaller screens */
        padding: 3px ; /* Adjust padding for smaller screens */
        font-size: 17px;
        text-align: justify;
        
    }
    .title_ar h2{
        width: 100%; /* Make the element wider on smaller screens */
         /* Adjust padding for smaller screens */
        font-size: 20px;
        text-align: center;
    }
    
    

}